<template>
	<div class="imofalform3itemsFormHeader puiformheader">
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.imofalform3items.imofalform3id')" :value="getImofalform3idValue"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'imofalform3items-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'imofalform3items'
		};
	},
	computed: {
		getImofalform3idValue() {
			return this.model && this.model.imofalform3id ? this.model.imofalform3id : '-';
		},
	}
}
</script>
